import Contract from 'Sp/Contract';

/**
 * @ngdoc service
 * @name sp.common.service:spContract
 *
 * @description
 * Handles parsing the Contract body to include formatted tokens.
 */
export default [
    'translateFilter',
    function spContract(translateFilter) {
        const service = this;
        const COMPLETED_FILLABLE_FIELD_TOKEN =
            /<(tokenshort|tokenlong) class="redactor-component" data-redactor-type="(fillableShortField|fillableLongField)" data-populated="true">((\s|\S)*?)<\/(tokenshort|tokenlong)>/gi;
        const COMPLETED_TRANSFORMED_FILLABLE_FIELD_TOKEN =
            /<(textarea|input|label) id="(.*?)" class="(fillable-field-long|fillable-field-short)" size="(.*?)" value="(.*?)"><\/(textarea|input|label)>/gi;
        const FILLABLE_FIELD_TOKEN =
            /<(tokenshort|tokenlong) class="redactor-component" data-redactor-type="(fillableShortField|fillableLongField)">(.*?)<\/(tokenshort|tokenlong)>/gi;
        const TRANSFORMED_FILLABLE_FIELD_TOKEN =
            /<(textarea|input) id="(.*?)" class="(fillable-field-long|fillable-field-short)" size="(.*?)" placeholder="(.*?)" (.*?)><\/(textarea|input)>/gi;
        const PLACEHOLDER_TOKEN = /placeholder="(.*?)"/;

        Object.assign(service, {
            getFillableFieldTokens,
            replaceBlanks,
            replaceFillableFields
        });

        return service;

        /**
         * @ngdoc method
         * @name getFillableFieldTokens
         * @methodOf sp.common.service:spContract
         *
         * @description
         * Parses the given html to find Fillable Field tokens and maps them
         * to an array with id and fillableFieldToken attributes
         *
         * @param {string} bodyHtml Contract body to be parsed
         *
         * @returns {array<object>} List of fillable field objects
         */
        function getFillableFieldTokens(bodyHtml, isCompletedFillableField = false) {
            let fillableFieldTokens = bodyHtml.match(
                isCompletedFillableField
                    ? COMPLETED_TRANSFORMED_FILLABLE_FIELD_TOKEN
                    : TRANSFORMED_FILLABLE_FIELD_TOKEN
            );

            return fillableFieldTokens && fillableFieldTokens.length
                ? fillableFieldTokens.map((token, index) => {
                      const placeholder = PLACEHOLDER_TOKEN.exec(token);

                      return {
                          id: `${index}-fib`,
                          index,
                          value: '',
                          descriptionText: placeholder[1]
                      };
                  })
                : [];
        }

        /**
         * @ngdoc method
         * @name replaceBlanks
         * @methodOf sp.common.service:spContract
         *
         * @description
         * Parses the given html to find Blank tokens and replace them with formatted elements.
         *
         * @param {string} bodyHtml Contract body to be parsed
         *
         * @returns {string} Contract body including parsed tokens
         */
        function replaceBlanks(bodyHtml) {
            const emptyBlankToken = new RegExp(
                `<token data-token="${Contract.BLANK}"></token>`,
                'g'
            );

            return bodyHtml.replace(
                emptyBlankToken,
                `<token data-token="${Contract.BLANK}">${Contract.BLANK}</token>`
            );
        }

        /**
         * @ngdoc method
         * @name replaceFillableFields
         * @methodOf sp.common.service:spContract
         *
         * @description
         * Parses the given html to find Fillable Field tokens and replace them with formatted
         * elements.
         *
         * @param {string} bodyHtml Contract body to be parsed
         * @param {boolean} disabled Value of whether the current set of tokens should be disabled
         * @param {boolean} isCompletedFillableField Value of whether the current set of tokens
         *  are completed filllable fields
         *
         * @returns {string} Contract body including parsed tokens
         */
        function replaceFillableFields(
            bodyHtml,
            isClient = false,
            disabled = true,
            isCompletedFillableField = false
        ) {
            let index = 0;
            const FILLABLE_FIELD_PATTERN = isCompletedFillableField
                ? COMPLETED_FILLABLE_FIELD_TOKEN
                : FILLABLE_FIELD_TOKEN;

            return bodyHtml.replace(
                FILLABLE_FIELD_PATTERN,
                (_, tokenType, matchedType, matchedText) => {
                    return getFillableFieldReplacedToken(_, matchedText);
                }
            );

            function getFillableFieldReplacedToken($token, matchedText) {
                const fillableFieldName = isClient
                    ? matchedText
                    : translateFilter('common.contractFillableField', {
                          fieldName: matchedText
                      });
                const isShortField = $token.includes('fillableShortField');
                const { fieldType, id, inputType, inputLength, isDisabled, textareaValue } =
                    getFillableFieldReplaceableData(isShortField, fillableFieldName);

                return isCompletedFillableField
                    ? `<${inputType} id="${id}" class="fillable-field-${fieldType}" ${inputLength} ${isDisabled} value="${fillableFieldName}">${textareaValue}</${inputType}>`
                    : `<${inputType} id="${id}" class="fillable-field-${fieldType}" ${inputLength} placeholder="${fillableFieldName}" ${isDisabled} autocomplete="nope"></${inputType}>`;
            }

            function getFillableFieldReplaceableData(isShortField, fillableFieldName) {
                const textPadding = 2;

                return {
                    fieldType: isShortField ? 'short' : 'long',
                    id: `${index++}-fib`,
                    inputType: isShortField
                        ? 'input'
                        : isCompletedFillableField
                        ? 'label'
                        : 'textarea',
                    inputLength: isShortField
                        ? `size="${fillableFieldName.length + textPadding}"`
                        : `size="auto"`,
                    isDisabled: disabled ? `disabled` : ``,
                    textareaValue: isShortField ? '' : fillableFieldName
                };
            }
        }
    }
];
